<template>
    <div class="box">
       <div class="box_banner">
           <div class="box_banner_mid">
               <p class="banner_mid_title">新闻资讯  </p>
               <p class="banner_mid_value">行业资讯发展动态，一览无余</p>
           </div>
       </div>
        <div class="cont">
            <div class="cont_left">
                <div class="cont_left_list">
                    <span class="left_list_dian"></span>
                    新闻动态
                </div>
                 <div class="cont_left_list cont_left_active">
                    <span class="left_list_dian"></span>
                    新闻动态
                </div>
            </div>
            <div class="cont_right">
                <div class="cont_right_list" v-for="(item,i) in 4" :key="i">
                    <p class="right_title">
                        2019全球区块链数字钱包生态会暨云链
                    </p>
                    <p class="right_time">源自 云链动态 2019-02-24</p>
                    <p class="right_brief">
                        包括腾讯、蚂蚁金服、百度、金融壹账通等机构，
                        都在近期加紧了区块包括腾讯、蚂蚁金服、百度、金融壹账通等机构，
                        都在近期加紧了区块包括腾讯、蚂蚁金服、百度、金融壹账通等机构，
                        都在近期加紧了区块包括腾讯、蚂蚁金服、百度、金融壹账通等机构，都在近期加紧…
                    </p>
                    <p class="right_detailts">案例详情→</p>
                </div>
            </div>
        </div>
        <div class="page">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                background
                :current-page="currentPage4"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="400">
            </el-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
           currentPage4:1,

        }
    },
    created() {
        this.getdata()
    },
    methods: {
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        getdata(){
            // let data={
            //     "type":"news",
            //     "offset":0,
            //     "count":20
            // }
            // this.$http.post("https://api.weixin.qq.com/cgi-bin/material/batchget_material",data).then((res)=>{
            //     console.log(res)
            // })
        }
    },
}
</script>
<style scoped>
.cont{
    width: 1200px;
    margin: auto;
    padding-top: 56px;
    display: flex;
}
.cont_left{
    width: 159px;
}
.cont_right{
    flex: 1;
}
.cont_left_list{
    border-right: 1px solid #CBD6EC;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color:#3F454B;
    font-size: 18px;
}
.left_list_dian{
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
    background-color: #3A7BFF;
    margin-right: 25px;
}
.cont_left_active{
    color: #3075FF;
    border-color: #3075FF;
    font-weight: 600;
}
.cont_right_list{
    margin-left: 26px;
    margin-bottom: 50px;
}
.right_title{
    color: #1D2023;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0;
    padding-left: 29px;
    background-image: url("../assets/advisory_icon.png");
    background-size: 19px 17px;
    background-repeat: no-repeat;
    background-position: left;
}
.right_time{
    color: #1D2023;
    font-size: 14px;
    padding: 5px 0;
    margin-left: 29px;
}
.right_brief{
    color: #78828C;
    font-size: 16px;
    line-height: 26px;
font-weight: 400;
    padding: 5px 0;
    margin-left: 29px;
}
.right_detailts{
    color: #3075FF;
    font-size: 14px;
    padding: 5px 0;
    margin-left: 29px;
}
.page{
    width: 1200px;
    margin: auto;
    text-align: center;
    padding-bottom: 80px;
}
.box_banner{
    width: 100%;
    height: 437px;
    background-image: url("../assets/banner_img.png");
    background-size: 100% 437px;
}
.box_banner_mid{
    width: 1200px;
    margin: auto;
    color: #FFFFFF;
    font-size: 46px;
    font-weight: 600;
    position: relative;
    padding-top: 140px;
    box-sizing: border-box;
}
.banner_mid_title span{
     color: #78A0FF;
     font-size: 38px;
}
.banner_mid_value{
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;   
    /* text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.49); */
}
</style>